import React from "react";
import { Link } from "gatsby";
import { Layout } from "StaticPages/Layouts";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import { Box, Hidden, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useWindowSize } from "mui-extensions/src/Hooks/useWindowSize";
import { TransitionStaticImage } from "StaticPages/Components/TransitionImage";
import { SkewAnimation } from "StaticPages/Components/Animations/SkewAnimation";
import { SectionTitle2 } from "StaticPages/Components/Commons/SectionTitle2";
import { SectionText1 } from "StaticPages/Components/Commons/SectionText1";
import { PageSummary } from "StaticPages/Components/Commons/PageSummary";

export default () => {
    const { t } = useTranslation();
    return <>
        <Helmet title={t("AI - AIを使った未来 | VISUALIZ inc")} />
        <Layout>
            <div>
                <Main />
            </div>
        </Layout>
    </>;
};

const Main = () => {
    const [t] = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const size = useWindowSize(500);
    return (
        <main>
            <PageSummary
                title={t("AIを使った未来")}
                subTitle={t("AI Vision")}
                description={<>
                    {t("可能性は未知数で無限大。")} <br />
                    {t("プロジェクトのスタートにあったものはビジョンだけ。")}<br />
                    {t("スタートから１年、ゴールが見えてきたAIプロジェクトの全貌について。")}
                </>}
                // eslint-disable-next-line @typescript-eslint/no-var-requires
                src={require("../../../static/images/AI_top.jpg").default}
            />

            <Box
                sx={{
                    background: theme.palette.background.default,
                }}
                p={{ xs: 3, sm: 4, md: 6 }}
            >
                <SectionTitle2
                    title={t("VISUALIZが考えるAI")}
                />
                <Box
                    my={{ xs: 3, sm: 4, md: 6 }}
                >
                    <SectionText1>
                        {t("誰でも安価に手に入れることができ、アイデア次第で強力なイノベーションを起こすことができるAIほど、世界の未来を変える力を持つものはない。しかもAIはインターネットに組み込まれることにより、世界中を瞬時に覆う力を持つことができる。大きな社会変化の例として挙げられる産業革命の比ではないほどの大変化を、猛烈なスピードと地球規模で起こすことが可能になると僕たちは考えているのだ。溢れかえる情報から最適解を見つけイノベーションを起こすために、AIを使わない手はない。")}
                    </SectionText1>
                    <SectionText1>
                        {t("ヒトの仕事を奪う脅威としても、ヒトに幸せな暮らしをもたらす万能神としても語られるAIだが、僕たちはあくまでもツールのひとつとして捉えている。過度の期待もないけれど、過小評価もしてはいない。新しいテクノロジーは正しく使ってこそ、その真価は発揮されるものだ。それは多くの歴史が証明している。")}
                    </SectionText1>
                    <SectionText1>
                        {t("僕たちはAIに付随するあらゆる技術を柔軟に取り入れながら、イノベーションを起こしていきたい。")}
                    </SectionText1>
                </Box>
            </Box>

            <Box
                p={{ xs: 3, sm: 4, md: 6 }}
            >
                <SectionTitle2
                    title={t("プロポーザル情報特化型AIーBidナビ")}
                />
                <Box
                    my={{ xs: 3, sm: 4, md: 6 }}
                >
                    <SectionText1>
                        {t("Bid（＝入札）ナビゲーション。VISUALIZが手掛ける最初のAI事業はここからスタートする（若しくは「した」）。ユーザーが必要とする情報をAIが自動収集→情報をデータベース化して、必要な情報ごとにサイトページを自動生成→利用顧客データや入金の管理までAIで一元管理する。人の時間を必要以上に奪う事務処理をAIで全て代行することで、業務の効率化、働く人たちの余剰時間を創出するサービスをとスタートしたのだ。ユーザーは行政書士・司法書士・社会労務士などの士業をターゲットに考えた。全国に約4000もある自治体や省庁から不定期に出される、助成金や補助金情報、入札やプロポーザル案件を効率良く収集し、必要とする人へちゃんと届ける必要があるのではと常々思っていたからだ。")}
                    </SectionText1>
                    <SectionText1>
                        {t("いつ、どこで、どのような情報が公開されるかなんて、誰にも分からない。定期的に人がチェックし続けるのも効率が悪すぎるし、情報量が膨大すぎる。そこでBidナビの出番だ。欲しい情報の条件、例えばエリア・分野などを登録しておけば、条件を満たす案件が公示された時にのみ、ユーザーにその情報を届ける。必要な情報を効率良く収集し、日々の業務や営業活動に役立てて欲しい。利用料金も類似サイトよりも破格の価格設定だ。")}
                    </SectionText1>
                    <SectionText1>
                        {t("働き方改革が叫ばれて久しいが、人の意識変革だけでなく、取り入れれば確実に余剰時間を創出するサービスやプロダクトが、何よりも必要だと僕たちは考えている。")}
                    </SectionText1>
                </Box>
            </Box>
        </main >
    );
};
